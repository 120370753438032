.about {
  width: 100%;
  max-width: 550px;

  @media screen and (max-width: 891px) {
    max-width: 100%;
  }
  
  h1 {
    text-align: center;
    font-size: 2rem;
    line-height: 2rem;
    margin: 0;
    @media screen and (max-width: 891px) {
      font-size: 1.25rem;
      line-height: 1.2rem;
      margin: 0 0 0.25rem;
    }
    @media screen and (max-width: 330px) {
      font-size: 1rem;
    }
    span {
      font-family: 'punkkidregular';
      font-size: 2.5rem;
      @media screen and (max-width: 891px) {
        font-size: 1.75rem;
      }
      @media screen and (max-width: 330px) {
        font-size: 1.25rem;
      }
    }
  }
  .img-mapper-map {
    cursor: pointer;
    width: 100%;
    //max-width: 550px;
  }
}
.bandmates-list {
  display: flex;
  position: relative;
  justify-content: space-between;
  opacity: 0;
  transition: 500ms ease-in opacity;
  margin-bottom: 1rem;

  @media screen and (max-width: 891px) {
    justify-content: center;
  }

  &.load-bandmate-list {
    opacity: 1;
  }

  .bandmate-name {
    position: absolute;
    transform: translate3d(-50%, 0%, 0);
    pointer-events: none;
    z-index: 1000;
  }
}
