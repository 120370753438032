$main_color: #ff166d;
$main_color_dark: darken($main_color, 30%);
$gray: #6b6b6b;
$gray_dark: #333;

.section {
  text-align: center;

  h1 {
    font-size: 3.75rem;
    margin: 0;
    line-height: 0.75;
    @media screen and (max-width: 800px) {
      font-size: 2.5rem;
    }
  }
  h4 {
    font-size: 2.25rem;
    margin: 0;
    @media screen and (max-width: 800px) {
      font-size: 2rem;
    }
  }
  strong {
    font-size: 2rem;
    display: block;
    line-height: 0.75;
    color: $gray;
    @media screen and (max-width: 800px) {
      font-size: 1.5rem;
    }
    .section-bg-alt & {
      color: $gray_dark;
    }
  }

  &.section-bg-black {
    color: $main_color;
    a {
      color: $gray;
      text-decoration: none;
    }
  }

  &.section-bg-alt {
    color: #000;
    background-color: $main_color;

    strong {
      color: $gray_dark;
    }
  }

  img {
    display: block;
    max-width: 100%;
  }
  .section-social-links {
    display: flex;
    justify-content: center;
    margin: 2rem 0 0;
    padding: 0;
    list-style: none;
    .section-social-link {
      width: 40px;
      margin: 0 0.5rem 0 0;
      img {
        width: 40px;
        height: auto;
        margin: 0 auto;
        -webkit-transistion: 250ms all cubic-bezier(0.67, -0.91, 0.25, 1.81);
        transition: 250ms all cubic-bezier(0.67, -0.91, 0.25, 1.81);

        &:hover {
          -webkit-transform: scale(0.9);
          transform: scale(0.9);
        }
      }
    }
    @media screen and (max-width: 800px) {
      margin: 1.25rem 0 0;
    }
  }
  .section-alt-text {
    color: $main_color;
  }
  .section-dark-alt-text {
    color: $main_color_dark;
  }
}

.section-padding {
  padding: 2rem;
  box-sizing: border-box;
  .section-inner {
    margin-top: 2rem;
  }
  p {
    margin: 0;
  }
  @media screen and (max-width: 800px) {
    padding: 1.25rem;
    .section-inner {
      margin-top: 1.25rem;
    }
  }
}

.section-logo,
.section-small-logo {
  -webkit-transition: 250ms all cubic-bezier(0.67, -0.91, 0.25, 1.81);
  transition: 250ms all cubic-bezier(0.67, -0.91, 0.25, 1.81);
  &:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}

.section-logo {
  display: block;
  width: 65%;
  height: auto;
  max-width: 550px !important;
  margin: 0 auto;
}

.section-video-embed {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  .section-video-container {
    overflow: hidden;
    position: relative;
    width: 100%;

    &:after {
      padding-top: 56.25%;
      display: block;
      content: "";
    }

    .section-video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.section-return-home {
  display: flex;
  position: absolute;
  top: 1rem;
  left: 1rem;
  justify-content: flex-start;
  align-items: center;
  -webkit-transition: 250ms all cubic-bezier(0.67, -0.91, 0.25, 1.81);
  transition: 250ms all cubic-bezier(0.67, -0.91, 0.25, 1.81);
  font-size: 1.1rem;

  &:before {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 10px 6px 0;
    border-color: transparent $gray transparent transparent;
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    margin-right: 0.25rem;
  }

  &:hover {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

.section-grid {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  .section-grid-item {
    &.large {
      width: calc(100% - 300px - 2rem);
      max-width: 530px;
      margin-right: 2rem;
    }
    &.small {
      width: 300px;
    }
    &.section-about {
      width: 45%;
      display: flex;
      flex-direction: column;
      p {
        font-size: 0.95vw;
      }
      h4 {
        font-size: 3vw;
      }
    }
    &.section-gallery {
      width: 55%;
      .swiper-container {
        height: 0;
        padding-bottom: 66.6%;
        background-color: #000;

        .swiper-wrapper {
          position: absolute;
          top: 0px;
          left: 0px;
          height: 100%;
          width: 100%;

          .swiper-slide {
            height: 100%;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            cursor: pointer;
          }
        }

        .swiper-pagination {
          .swiper-pagination-bullet {
            background: #aaa;
            opacity: 0.4;
          }
          .swiper-pagination-bullet-active {
            background: #fff;
            opacity: 1;
          }
        }

        .swiper-button-next {
          right: 0;
        }
        .swiper-button-prev {
          left: 0;
        }

        .swiper-button-next,
        .swiper-button-prev {
          top: 0;
          margin-top: 0;
          width: 40px;
          height: 100%;
          color: #fff;
          &:before,
          &:after {
            font-size: 30px;
          }
          @media screen and (max-width: 800px) {
            display: none;
          }
        }

        .swiper-pagination-bullet:focus,
        .swiper-button-next:focus,
        .swiper-button-prev:focus {
          outline: 0;
        }
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }
  &.spaced .section-grid-item {
    margin-right: 2rem;
  }
  .section-grid-title {
    font-size: 1.4rem;
    @media screen and (max-width: 800px) {
      font-size: 0.95rem;
    }
  }
  @media screen and (max-width: 1050px) {
    &.b-1050 {
      flex-direction: column;
      .section-about,
      .section-gallery {
        width: 100%;
      }
      .section-about {
        p {
          font-size: 0.9rem;
        }
        h4 {
          font-size: 2.25rem;
        }
      }
      .section-management {
        margin: 2rem 0;
      }
    }
  }
  @media screen and (max-width: 800px) {
    &.b-1050 {
      .section-about {
        p {
          font-size: 0.75rem;
        }
        h4 {
          font-size: 1.75rem;
        }
      }
      .section-management {
        margin: 1.25rem 0;
        .section-contact {
          font-size: 1.45rem;
        }
      }
    }
    &.b-800 {
      flex-direction: column;
      align-items: center;
      .section-playlist {
        height: 400px;
      }
      .section-grid-item.large {
        margin-right: 0;
        margin-bottom: 1rem;
        width: 100%;
      }
      .section-grid-item.small {
        width: 100%;
        .section-playlist {
          width: 100%;
        }
      }
    }
  }
}

.section-stretch {
  flex: 1 0 auto;
}

.section-links {
  display: flex;
  flex-direction: column;
}

.section-management {
  flex-grow: 1;
  align-items: center;
  .section-contact {
    display: block;
    color: #000;
    font-size: 1.95rem;
    line-height: 0.9;
    -webkit-transition: 250ms all cubic-bezier(0.67, -0.91, 0.25, 1.81);
    transition: 250ms all cubic-bezier(0.67, -0.91, 0.25, 1.81);

    &:hover {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }
  }
}

.section-download {
  display: flex;
  flex-direction: column;
  align-items: center;
  .section-download-title {
    position: relative;
    display: block;
    font-size: 2.5rem;
    color: #000;
    text-decoration: none;
    line-height: 0.7;
    -webkit-transition: 250ms all cubic-bezier(0.67, -0.91, 0.25, 1.81);
    transition: 250ms all cubic-bezier(0.67, -0.91, 0.25, 1.81);
    .larger {
      font-size: 3.25rem;
    }
    &:before,
    &:after {
      content: "";
      position: absolute;
      background-image: url("../images/shock-pink.png");
      background-size: 100% auto;
      width: 55px;
      height: 65px;
      top: 0;
      -webkit-transition: 250ms all cubic-bezier(0.67, -0.91, 0.25, 1.81);
      transition: 250ms all cubic-bezier(0.67, -0.91, 0.25, 1.81);
    }
    &:before {
      left: -42px;
    }
    &:after {
      right: -42px;
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
    }
    &:hover {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
      &:before {
        left: -45px;
      }
      &:after {
        right: -45px;
      }
    }
  }
  .section-download-includes {
    position: relative;
    display: block;
    width: 190px;
    text-align: center;
    font-size: 0.65rem;
    padding: 0.5rem 0.55rem;
    margin-bottom: -0.5rem;

    &:before,
    &:after {
      position: absolute;
      font-size: 1.75rem;
      display: block;
      top: 50%;
      -webkit-transform: translate3d(0, -50%, 0);
      transform: translate3d(0, -50%, 0);
    }

    &:before {
      content: "(";
      left: 0;
    }
    &:after {
      content: ")";
      right: 0;
    }
  }
}

.section-dev {
  margin-top: 2rem;
  font-size: 2.15rem;
  .section-dev-title {
    line-height: 0.9;
    font-size: 1.75rem;
  }
  .section-dev-link {
    display: inline-block;
    -webkit-transition: 250ms all cubic-bezier(0.67, -0.91, 0.25, 1.81);
    transition: 250ms all cubic-bezier(0.67, -0.91, 0.25, 1.81);
    &:hover {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }
  }
}

.section-playlist {
  height: 100%;
}

.section-footer {
  .section-small-logo {
    display: block;
    margin: 0 auto;
    width: 150px;
  }
}

.section-header {
  .section-inner {
    margin-top: 0;
  }
}

.section-light-font {
  font-family: "Arial Rounded MT Bold", "Helvetica Rounded", Arial, sans-serif;
  text-transform: none;
  font-size: 0.85rem;
  text-align: left;

  &.section-text-uppercase {
    text-transform: uppercase;
  }
}
