.contact-form {
  width: 100%;
  max-width: 400px;
  .contact-form-group {
    border: 0;
    padding: 0;
    margin: 0 0 1.25rem;
    box-sizing: border-box;
    @media screen and (max-width: 891px) {
      margin: 0 0 1rem;
    }
    @media screen and (max-width: 330px) {
      margin: 0 0 0.75rem;
    }
  }
  .contact-form-label {
    display: block;
    margin: 0 0 0.25rem 0.5rem;
    font-size: 1.2rem;
  }
  .contact-form-input {
    display: block;
    padding: 10px;
    border-width: 0;
    border-radius: 8px;
    outline: 0;
    font-size: 1.25rem;
    width: 100%;
  }
  textarea.contact-form-input {
    resize: none;
  }
  button {
    float: right;
    font-family: 'cipitilloregular', sans-serif;
    background: #fe0161;
    border: 1px solid darken(#fe0161, 10%);
    color: #fff;
    padding: 5px 10px;
    font-size: 1.25rem;
    border-radius: 8px;
  }
  &:after {
    clear: right;
  }
}
.thankyou_message {
  display: none;
}
