.home {
  max-width: 400px;
  width: 100%;

  @media screen and (max-width: 700px) {
    max-width: 300px;
  }

  @media screen and (max-width: 400px) {
    max-width: 200;
  }

  .album {
    .album-text {
      margin-top: 1rem;
    }
    img {
      display: block;
      width: 100%;
    }
  }
}