.qb-sidebar {
  .sidebar-group {
    width: 150px;
    margin: 0 3rem 0 2rem;
    overflow: hidden;

    @media screen and (max-width: 891px) {
      margin: 2rem 1rem 1rem;
    }

    a {
      position: relative;
      left: -16px;
      display: block;
      font-size: 1.75rem;
      margin-bottom: 0.25rem;
      color: #fff;
      outline: 0;
      text-decoration: none;
      transition: 500ms left cubic-bezier(0.67,-0.91,0.25,1.81);

      &:before {
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8px 0 8px 13.9px;
        border-color: transparent transparent transparent #fff;
        transform:rotate(360deg);
        padding-right: 5px;
        opacity: 0;
        transition: 500ms linear opacity;
      }

      &.current-page {
        left: 0;
        &:before {
          opacity: 1;
        }
      }
    }
  }
}

.bm-burger-button {
  position: fixed;
  width: 40px;
  height: 30px;
  right: 1.5rem;
  top: 1.5rem;

  button {
    outline: 0;
  }
}

.social-links {
  width: 104px;
  display: flex !important;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0.75rem 0 0;
  padding: 0;
  list-style: none;
  .social-link {
    margin: 0;
    width: 50px;
    .social-icon {
      display: block;
      left: 0;
      &:before {
        content: none;
      }
      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }
}

.bm-burger-bars, .bm-cross {
  background: #fff;
  border-radius: 8px;
}

.bm-morph-shape {
  fill: #373a47;
}

.bm-overlay {
  top: 0;
  left: 0;
}

.bm-menu-wrap {
  top: 0;
  background: #ea6f9a;
}
