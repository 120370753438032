.area {
  width: 35vw;
  height: 48vh;
  @media screen and (max-width: 1160px) {
    width: 50vw;
  }
  @media screen and (max-width: 891px) {
    height: 100%;
    width: 100%;
  }

  .scrollarea-content {
    touch-action: auto;
  }
}
.scrollarea {
  .scrollbar-container {
    opacity: 1 !important;
    &.active,
    &:hover {
      opacity: 1 !important;
    }
  }
  &:hover .scrollbar-container {
    opacity: 1 !important;
  }
}

.all-shows h3 {
  font-size: 1.75rem;
  line-height: 1.4rem;
  margin: 0 0 0.5rem;
  font-weight: 300;
}
.upcoming-shows {
  margin-bottom: 1.5rem;
  @media screen and (max-width: 330px) {
    margin-bottom: 1rem;
  }
}

.show-list {
  opacity: 0;
  transition: 500ms ease-in opacity;

  &.load-show-list {
    opacity: 1;
  }
}

.shows {
  .show {
    display: flex;
    margin: 0 0 1rem;

    .show-poster {
      flex-shrink: 0;
      width: 100px;
      margin: 0 1rem 0 0;
      @media screen and (max-width: 330px) {
        width: 70px;
      }
      img {
        width: 100%;
        cursor: pointer;
      }
    }
    .show-info {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      time {
        font-size: 1.5rem;
        @media screen and (max-width: 891px) {
          font-size: 1.25rem;
        }
        @media screen and (max-width: 330px) {
          font-size: 1rem;
        }
      }
      h2 {
        font-size: 1.85rem;
        margin: 0;
        line-height: 1.15;
        @media screen and (max-width: 891px) {
          font-size: 1.42rem;
        }
        @media screen and (max-width: 330px) {
          font-size: 1.1rem;
        }
      }
      div {
        font-size: 1rem;
        @media screen and (max-width: 891px) {
          font-size: 0.9rem;
        }
        &.text {
          flex-grow: 1;
        }
      }
      a {
        color: #fff;
        text-decoration: underline;
      }
      a, span {
        font-size: 1.25rem;
        @media screen and (max-width: 891px) {
          font-size: 1rem;
        }
      }
    }
  }
}

.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(#fe0161, 0.75);
  z-index: 9999;

  .image-wrap {
    position: relative;
    height: 70vh;
    display: flex;
    justify-content: center;
    padding: 1.5rem;
    img {
      height: 100%;
      width: auto;
    }
  }
}
