@font-face {
  font-family: "cipitilloregular";
  src: url("../font/cipitillo-webfont.woff2") format("woff2"),
    url("../font/cipitillo-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "punkkidregular";
  src: url("../font/punk_kid-webfont.woff2") format("woff2"),
    url("../font/punk_kid-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  color: #fff;
  font-family: "cipitilloregular", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  padding: 0;
  margin: 0;

  &.main-app {
    background-color: #fe0161;
  }

  &.presskit {
    background-color: #000;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
strong {
  font-weight: 300;
}

.app {
  padding: 2rem;
  @media screen and (max-width: 891px) {
    padding: 1.5rem;
  }
}

.site-container {
  display: flex;
  .page {
    flex-grow: 1;
    width: 100%;
  }
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
