.qb-header {
  margin: 0 0 1.5rem;
  overflow: hidden;
  @media screen and (max-width: 891px) {
    margin: 0 0 1rem;
  }
  @media screen and (max-width: 330px) {
    margin: 0 0 0.75rem;
  }
  .logo {
    float: left;
    width: 250px;
    @media screen and (max-width: 891px) {
      width: 150px;
    }
    @media screen and (max-width: 330px) {
      width: 100px;
    }
    a {
      display: block;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
}
